<template>
	<div class="kosa-popup">
		<div class="close">
			<img src="/images/mem/close.png" alt="닫기" @click="$emit('close')"/>
		</div>
		<div class="tit">KOSA 경력관리 조회</div>
		<div class="guide">KOSA 경력관리 조회를 위한<br/>KOSA 로그인 아이디, 비밀번호를 입력해 주세요.</div>
		<div class="loginform">
			<div class="formlist">
				<span>KOSA 아이디</span>
				<input type="text" id="kosa_id" class="kosa" v-model="data.kosaId">
			</div>
			<div class="formlist">
				<span>KOSA 비밀번호</span>
				<input type="password" id="kosa_pw" class="kosa" v-model="data.kosaPw">
			</div>
		</div>
		<div class="btn">
			<div class="search" @click="searchKOSA">조회</div>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			data : {
				kosaId : '',
				kosaPw : ''
			}
		}
	},
    methods: {
		searchKOSA(){
            //console.log('PRF101M01', 'searchKOSA', 'req', this.data);
            this.$.httpPost('/api/mem/prf/getKOSA', this.data)
				.then(res => {
					//console.log('PRF101P01', 'searchKOSA', 'res', res.data);
                    if(res.data.errMsg){
                        alert(res.data.errMsg);
                    }
					this.$emit("close", res.data);
				})
				.catch(err => {
					alert(err.response.data.error_description);
				});
		}
	}
}
</script>